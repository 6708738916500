<template>
    <div class="container">
        <b-card>
            <b-row>
                <b-col cols="4" class="mt-1">
                    <label for="minimum_withdraw_coins">Minimum withdraw coins</label>
                    <b-form-input id="minimum_withdraw_coins" v-model="form.minimum_withdraw_coins" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="maximum_monthly_withdraw_coins">Maximum monthly withdraw coins</label>
                    <b-form-input id="maximum_monthly_withdraw_coins" v-model="form.maximum_monthly_withdraw_coins" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="coin_sell_price_percent">Coin sell price percent</label>
                    <b-form-input id="coin_sell_price_percent" v-model="form.coin_sell_price_percent" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="send_coin_commission_percent">Send coin commission percent</label>
                    <b-form-input id="send_coin_commission_percent" v-model="form.send_coin_commission_percent" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="editor">Last editor</label>
                    <h4></h4>
                    <b-badge variant="primary" class="badge-glow" id="editor">
                        {{ form.editor.name }}
                    </b-badge>
                </b-col>
                <b-col cols="12" class="text-center">
                    <b-button @click="submitHandler" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="danger" class="mt-1"> Submit </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BPopover, BBadge, BListGroup, BListGroupItem, BTable, BAvatar, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay, BPagination } from "bootstrap-vue";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
import useWalletList from "./useWalletList";
const userRepository = new UserRepository();

export default {
    components: {
        BPopover,
        BBadge,
        BListGroup,
        BListGroupItem,
        BPagination,
        BTable,
        BAvatar,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { getConfigs, setConfig } = useWalletList();
        const form = ref({});
        getConfigs().then((res) => {
            form.value = res.data.data;
        });
        const submitHandler = () => {
            setConfig(form.value);
        };
        return { form, submitHandler };
    },
};
</script>

<style></style>
